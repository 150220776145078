// Component states
import states from './settings-accordion-modifier.state.js'
import subStates from '../settings-text-modifier/settings-text-modifier.state.js'

// Services
import { applyDrag, componentBuilder } from '@/services/utils/utils.service'
import { openEditPanel, landingPageMode } from '@/services/states/states.service'

// Components
import MdiEdit from 'vue-material-design-icons/SquareEditOutline.vue'
import MdiActionActive from 'vue-material-design-icons/Link.vue'
import MdiActionEmpty from 'vue-material-design-icons/LinkOff.vue'
import MdiDelete from 'vue-material-design-icons/Delete.vue'
import MdiCursorMove from 'vue-material-design-icons/CursorMove.vue'
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'
import SliderModifier from '../slider-modifier/Slider-modifier.vue'
import { Container, Draggable } from 'vue-smooth-dnd'

// Library
import {
  cloneDeep as _cloneDeep,
} from 'lodash-es'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Settings-accordion-mj'

// Vue@Properties
const props = {
  conf: Object,
  mjml: Object
}
// Vue@Properties

// Vue@subComponents
const components = {
  MdiEdit,
  MdiActionActive,
  MdiActionEmpty,
  MdiDelete,
  MdiCursorMove,
  Container,
  Draggable,
  SliderModifier,
  custColorPicker
}
// Vue@subComponents

// Vue@data
const data = function () {
  return {
    states
  }
}
// Vue@data

// Methods
const methods = {

  /**
   * Open Modal
   * @param  {Integer} index (Element index)
   */
  openModal (index) {
    openEditPanel({
      parent: { mjml: this.mjml },
      contents: this.mjml.children,
      isModal: true,
      modifiers: {
        // Import custom state for settings-m
        'Settings-mj-text': {
          states: _cloneDeep(subStates)
        }
      },
      index
    })
  },

  /**
   * Get title element when this one isn't on error
   * @param  {Object} element (Element)
   *
   * @return {String}       (src || placeholder)
   */
  getTitleElement (element) {
    return element.children[0].content
  },

  // Func@getTextElement
  /**
   * Get text element and truncate at 90 character maximum
   * @param  {Object} element (Element)
   *
   * @return {String}       (src || placeholder)
   */
  getTextElement (element) {
    return element.children[1].content
  },
  // Func@getTextElement

  // Func@addElement
  /**
   * Add new element
   */
  async addElement () {
    const lastItem = { ...this.mjml.children.at(-1) }
    const child = lastItem.children
    const newAccordionPanel = await componentBuilder({
      componentId: 'mj-accordion-element',
      withId: true,
      overwrite: {
        'mj-accordion-title': {
          attributes: {
            'background-color': child[0].attributes['background-color'],
            'font-family': child[0].attributes['font-family'],
            'css-class': child[0].attributes['css-class'],
            'font-size': child[0].attributes['font-size'],
            padding: child[0].attributes.padding,
            color: child[0].attributes.color
          },
          content: child[0].content
        },
        'mj-accordion-text': {
          attributes: {
            'background-color': child[1].attributes['background-color'],
            'font-family': child[1].attributes['font-family'],
            'css-class': child[1].attributes['css-class'],
            'font-size': child[1].attributes['font-size'],
            padding: child[1].attributes.padding,
            color: child[1].attributes.color
          },
          content: child[1].content
        }
      }
    })
    this.mjml.children.push(newAccordionPanel)
  },
  // Func@addElement

  // Func@deleteElement
  /**
   * Delete existing element
   * @param  {Integer} index (element position)
   */
  deleteElement (index) {
    if (this.mjml.children.length > 1) this.mjml.children.splice(index, 1)
  },
  // Func@deleteElement

  // Func@onDrop
  /**
   * To get the droped list and replace the current
   * @param  {Object} dropResult (D&D params)
   */
  onDrop (dropResult) {
    const newArray = applyDrag(this.mjml.children, dropResult)
    this.mjml.children.splice(0, this.mjml.children.length, ...newArray)
  },
  // Func@onDrop

  // Func@getPayload
  /**
   * Get DND payload
   * @param  {Number} index (position)
   *
   * @return {Object}       (payload)
   */
  getPayload (index) {
    return this.mjml.children[index]
  }
  // Func@getPayload
}

// Computed methods
const computed = {
  /**
   * Check DND mode
   */
  isLandingMode () {
    return landingPageMode().get()
  }
}

// Vue component syntax
export default {
  name,
  data,
  props,
  methods,
  computed,
  components
}
